const baseRoutes: Record<string, string> = {
  home: '/',
  dashboard: `/dashboard`,
  colaboradores: `/colaboradores`,
  servicios: `/servicios`,
  finanzas: `/finanzas`,
  cuentas: `/cuentas`,
  aprobaciones: `/aprobaciones`,
  makers: `/makers`,
  reportes: `/reportes`,
  configuracion: `/configuracion`,
  asignaciones: `/aprobaciones`,
  workflow: `/workflow`,
};
const { servicios, asignaciones, workflow } = baseRoutes;

const routes: Record<string, string> = {
  ...baseRoutes,
  setup: `${servicios}/setup`,
  encurso: `${servicios}/en-curso`,
  renovaciones: `${servicios}/renovaciones`,
  new: `${servicios}/setup/new-service`,
  modifyService: `${servicios}/setup/modify-service`,
  createService: `${servicios}/setup/create-service`,
  confirmService: `${servicios}/setup/confirm-service`,
  detailService: `${servicios}/setup/detail-service`,
  assignMakers: `${servicios}/en-curso/assign-makers`,
  assignMakersSecondary: `${servicios}/en-curso/assign-makers-secondary`,
  modifyMakersCurse: `${servicios}/en-curso/modify-makers`,
  pending: `${asignaciones}/pendientes`,
  observations: `${asignaciones}/observaciones`,
  finished: `${asignaciones}/finalizadas`,
  task: `${workflow}/task`,
  // companies: `${recruiters}/companies`,
  // candidateRequests: `${recruiters}/candidate-requests`,
  // recruitersOnboarding: `${recruiters}${welcome}`,
};

export default routes;
